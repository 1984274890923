<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row">
			<div class="col text-right">
				<b-button
					v-if="$can('banners', 'create')"
					variant="primary"
					@click="$router.push({ name: 'NewBanner'});">
					{{ translate('create') }}
				</b-button>
			</div>
		</div>
		<hr>
		<div v-if="!loading && data.length > 0">
			<div
				v-for="bannerInfo in data"
				:key="bannerInfo.id">
				<div class="row">
					<div class="col-xl-11 col-sm-10 col-9 pr-0">
						<banner-item
							:title="bannerInfo.attributes.title"
							:image="bannerInfo.attributes.image"
							:mobile-image="bannerInfo.attributes.mobile_image" />
					</div>
					<div class="col-xl-1 col-sm-2 col-3 mt-1 mb-2 text-left mt-5">
						<div
							v-if="$can('banners', 'update')"
							class="row">
							<div class="col-12">
								<b-button
									v-b-tooltip.hover
									:title="translate('update_banner')"
									class="mt-1 bg-primary-alt"
									variant="primary"
									@click="updateBanner(bannerInfo.id)">
									<i
										class="fa fa-pen" />
								</b-button>
							</div>
						</div>
						<div
							v-if="$can('banners', 'delete')"
							class="row">
							<div class="col-12">
								<b-button
									v-b-tooltip.hover
									:title="translate('delete_banner')"
									:style="{ width: '40px' }"
									class="mt-1 bg-primary-alt"
									variant="danger"
									@click="deleteBanner(bannerInfo.id)">
									<i
										class="fa fa-trash-alt" />
								</b-button>
							</div>
						</div>
					</div>
					<div class="col-xl-11 col-sm-10 col-11 pr-0">
						<div
							class="row"
							:class="['xs', 'sm'].includes(windowWidth) ? 'text-small' : ''">
							<div class="col-12 col-md-8 h5 text-left">
								<div v-if="bannerInfo.attributes.end_date">
									<i class="fa fa-clock align-icon" /> {{ translate('from') }} <span class="font-weight-normal font-italic">{{ $moment(bannerInfo.attributes.start_date.date).format('LLL') }}</span> {{ translate('to') }} <span class="font-weight-normal font-italic">{{ $moment(bannerInfo.attributes.end_date.date).format('LLL') }}</span>
								</div>
								<div v-if="!bannerInfo.attributes.end_date">
									<i class="fa fa-clock align-icon" /> {{ translate('starts_at') }}: <span class="font-weight-normal font-italic">{{ $moment(bannerInfo.attributes.start_date.date).format('LLL') }}</span>
								</div>
							</div>
							<div class="col-12 col-md-4 h5 text-left">
								<i class="fa fa-arrow-up align-icon" /> <span v-html="showPriority(bannerInfo.attributes.priority)" />
							</div>
						</div>
					</div>
					<div class="col-xl-11 col-sm-10 col-11 pr-0">
						<div
							class="row"
							:class="['xs', 'sm'].includes(windowWidth) ? 'text-small' : ''">
							<div class="col-12 col-md-8 h5 text-left">
								<i class="fa fa-map align-icon" /> <span class="font-weight-normal">{{ countriesText(bannerInfo.attributes.countries) }}</span>
							</div>
							<div class="col-12 col-md-4 h5">
								<i class="fa fa-user align-icon" /> <span>{{ userTypesText(bannerInfo.attributes.user_types) }}</span>
							</div>
						</div>
					</div>
					<div class="col-xl-11 col-sm-10 col-11 pr-0">
						<div
							class="row"
							:class="['xs', 'sm'].includes(windowWidth) ? 'text-small' : ''">
							<div class="col h5 text-left">
								<i class="fa fa-desktop align-icon" /> <span class="font-weight-normal">{{ devicesText(bannerInfo.attributes.device_type) }}</span>
							</div>
							<div
								v-if="bannerInfo.attributes.has_url"
								class="col-12 col-md-4 h5 pr-5">
								<div class="d-inline-flex w-100">
									<i class="fa fa-link align-icon align-self-center mr-2" />
									<div class="d-block align-self-end w-100">
										<a
											v-b-tooltip.hover
											:title="translate('open_in_new_tab')"
											:href="bannerInfo.attributes.url"
											target="_blank"
											class="d-flex">
											<span class="font-weight-normal text-truncate"> {{ bannerInfo.attributes.url }} </span>
											<i class="fas fa-external-link-alt text-small align-icon align-self-center ml-1" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="bannerInfo.attributes.show_in_dashboard"
						class="col-xl-11 col-sm-10 col-11 pr-0">
						<div
							class="row"
							:class="['xs', 'sm'].includes(windowWidth) ? 'text-small' : ''">
							<div class="col h5 text-left">
								<i class="fa fa-eye align-icon" /> <span class="font-weight-normal">{{ translate('show_in_dashboard') }}</span>
							</div>
						</div>
					</div>
				</div>
				<hr>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getData" />
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';
import { PRIORITIES, USER_TYPES } from '@/settings/Banners';
import { PAGINATION } from '@/settings/RequestReply';
import {
	Announcements, Banners as BannerMessages, Countries, Time, Tooltip, Users,
} from '@/translations';
import Banner from '@/util/Banner';
import BannerItem from './components/BannerItem';

export default {
	name: 'Banners',
	messages: [Announcements, BannerMessages, Countries, Time, Tooltip, Users],
	components: {
		BannerItem,
	},
	mixins: [WindowSizes],
	data() {
		return {
			banners: new Banner(),
			removeBanner: new Banner(),
			priorities: PRIORITIES,
			userTypes: USER_TYPES,
			alert: new this.$Alert(),
		};
	},
	computed: {
		pagination() {
			return this.banners.data.pagination;
		},
		loading() {
			return !!this.banners.data.loading;
		},
		errors() {
			return this.banners.data.errors.errors;
		},
		data() {
			try {
				const { data } = this.banners.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.banners.getBanners();
	},
	methods: {
		getData(page) {
			const { query } = this.$route;
			const options = { ...query };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			this.banners.getBanners(options);
		},
		deleteBanner(id) {
			const trans = {
				title: this.translate('delete_banner'),
				text: this.translate('password'),
			};
			const options = {
				buttonText: this.translate('ok'),
				cancelButtonText: this.translate('cancel'),
				emptyPasswordMessage: this.translate('password_is_required'),
			};
			this.alert.confirmationWithPassword(trans.title, trans.text, options).then((password) => {
				const payload = {
					password: password.value,
				};
				this.removeBanner.deleteBanner(id, payload).then(() => {
					try {
						this.alert.toast('success', this.translate('banner_deleted'));
					} finally {
						this.banners.getBanners();
					}
				}).catch(() => {
					let errorName = 'default_error_message';
					if (typeof this.removeBanner.data.errors.errors.password !== 'undefined') {
						errorName = 'incorrect_password';
						delete this.removeBanner.data.errors.errors.password;
					}
					this.alert.toast('error', this.translate(errorName));
					this.data = payload;
				});
			}).catch(() => {});
		},
		updateBanner(bannerId) {
			this.$router.push({ name: 'UpdateBanner', params: { bannerId } });
		},
		showPriority(priority) {
			return this.translate('priority_label', { priority: this.translate(priority) });
		},
		countriesText(countries) {
			if (countries === '') {
				return this.translate('all_countries_apply');
			}
			return countries.split(',').map((country) => this.translate(country.toLowerCase())).join(', ');
		},
		userTypesText(userTypes) {
			if (userTypes === '') {
				return this.translate('all_user_types_apply');
			}
			return userTypes.split(',').map((userType) => this.translate(userType.toLowerCase())).join(', ');
		},
		devicesText(deviceTypes) {
			if (deviceTypes === '') {
				return this.translate('all_device_types_apply');
			}
			return deviceTypes.split(',').map((deviceType) => this.translate(deviceType.toLowerCase())).join(', ');
		},
	},
};
</script>
<style scoped>
.align-icon {
	text-align: center;
	width: 20px;
}
span.text-small {
	font-size: 0.7em;
}
</style>
