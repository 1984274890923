const minor = 'minor';
const lowest = 'lowest';
const low = 'low';
const medium = 'medium';
const high = 'high';
const highest = 'highest';
const major = 'major';

export const PRIORITIES = {
	major,
	highest,
	high,
	medium,
	low,
	lowest,
	minor,
};

export const USER_TYPES = ['distributor', 'customer', 'affiliate'];
export const DEVICE_TYPES = ['backoffice', 'mobile'];

export const IMAGES_EXT = ['jpg', 'png', 'jpeg'];

export default {};
